import logo from './logo.svg';
import './App.css';
import CallToAction from './Components/CallToAction'
import CallToAction2 from './Components/CallToAction'
import Benefits from './Components/Benefits'
import Testimonials from './Components/Testimonials';
import FAQ from './Components/FAQ';
import Footer from './Components/Footer';
import Comparison from './Components/Comparsion';
import About from './Components/About';
import QuzionatorInfo from './Components/QuzionatorInfo';
import LanguageSwitcher from './Components/LanguageSwitcher';
import './i18n';

function App() {
  return (
    <div className="App">
      <CallToAction />
      {/* <LanguageSwitcher /> */}
      <Benefits />
      <QuzionatorInfo />

      <Comparison />
      <Testimonials />

      <About />
      <FAQ />
      <Footer />
      {/* <Header />
      <Features />
      */}
    </div>
  );
}

export default App;
