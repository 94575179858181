import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n
    .use(initReactI18next)
    .init({
        resources: {
            en: {
                translation: {
                    callToAction: {
                        heading1: "You also",
                        heading2: "don't want to",
                        heading3: "solve quizzes",
                        heading4: "in college?",
                        subheading: "Our extension will solve them for you. Quickly, easily and effectively.",
                        emailPlaceholder: "Enter your email",
                        privacyPolicy: "We never share your email with anyone.",
                        remainingSlots: "Remaining slots: ",
                    },
                    benefits: {
                        time: {
                            title: "More time",
                            description: "Save hours of studying subjects you need to pass but will never need.",
                        },
                        stress: {
                            title: "No stress",
                            description: "Say goodbye to panicked searching for answers on the internet during the test",
                        },
                        results: {
                            title: "Better results",
                            description: "Don't worry about passing subjects anymore!",
                        },
                    },
                    quizionatorInfo: {
                        title: "What is Quizonator?",
                        description: "Quizonator is a Chrome extension that automatically solves quizzes for you. <strong>Powered by the latest ChatGPT-4o</strong>, it quickly and accurately marks the correct answers, saving your time spent on learning useless subjects.",
                        point1: "Created by a student for students.",
                        point2: "Tested by students on real online exams.",
                        point3: "It's undetectable and safe on university platforms.",
                        point4: "It has a discreet feature that highlights the correct answers.",
                    },
                    comparison: {
                        title: "Comparison: Quizonator vs ChatGpt-4o",
                        automaticQuestionFinding: "Automatic question finding:",
                        responseSpeed: "Response speed:",
                        bypassingSecurity: "Bypassing university security:",
                        undetectability: "Undetectability on exams:",
                        yes: "Yes",
                        no: "No",
                        fast: 'about 1 second',
                        slow: 'about 10 seconds',
                    },
                    testimonials: {
                        title: "What are the impressions of the first testers?",
                        bartek: {
                            name: "Bartek",
                            title: "Student, Akademia Humanitas",
                            quote: "Thanks to the program, I actually passed the 1st year of studies, it works really well, it's very hard to detect...",
                        },
                        dominika: {
                            name: "Dominika",
                            title: "Student, WSB Merito University",
                            quote: "Awesome extension that made solving tests/quizzes even easier, giving me the guarantee of passing.",
                        },
                        wiktor: {
                            name: "Wiktor",
                            title: "Student, WSB Merito University",
                            quote: "Awesome safe mode option, even though the lecturer was standing over me, I knew which answers to mark, I recommend it",
                        },
                    },
                    about: {
                        intro: "If we haven't met yet...",
                        greeting: "Hi, I'm",
                        name: "Dominik!",
                        description1: "I'm a second-year computer science student, and my programming journey started when I was 13 years old. Coding has become my passion and a way to solve problems and make life easier for myself and others.",
                        description2: "<strong>As a student, I understand the value of time.</strong> That's why I created Quizonator - an application that <strong>automatically solves quizzes and exams</strong>, allowing you to focus on what really matters. Quizonator is functional and has been tested on one of the most popular educational platforms, and I'm constantly working on improving and expanding its compatibility with other universities.",
                        description3: "Join our community, <strong>try out</strong> Quizonator, and help us create a tool that will truly change the way of studying. <strong>Your support and feedback</strong> are incredibly valuable in further developing this application.",
                    },
                    faq: {
                        question1: "Does the extension work on all quiz websites?",
                        answer1: "Currently, our extension has been tested and is compatible with the Moodle platform, in automatic question detection mode. I'm constantly working on making it compatible with every online quiz website. This will be possible thanks to your help!",
                        question2: "Can the extension be detected by the university platform?",
                        answer2: "The extension has been designed to work in the background without interfering with the university platform's functionality. There have also been tests on exams with active browser tab change protection, and the extension worked flawlessly.",
                        question3: "Is my data safe?",
                        answer3: "Your data is fully secure. It is not shared with third parties, and advanced encryption methods are used. You can be sure that no unwanted person will find out that you're using our extension.",
                        question4: "Is using this tool legal?",
                        answer4: "Using Quizonator is similar to using ChatGPT or notes during an online exam. However, remember that universities have their own regulations regarding such practices.",
                    },
                }
            },
            pl: {
                translation: {
                    callToAction: {
                        heading1: "Też",
                        heading2: "nie chce Ci się",
                        heading3: "rozwiązywać quizów",
                        heading4: "na studiach?",
                        subheading: "Nasze rozszerzenie rozwiąże je za Ciebie. Szybko, łatwo i skutecznie.",
                    },
                    benefits: {
                        time: {
                            title: "Więcej czasu",
                            description: "Zaoszczędź godziny nauki przedmiotów, które musisz zdać, ale nigdy nie będą ci potrzebne.",
                        },
                        stress: {
                            title: "Brak stresu",
                            description: "Pożegnaj paniczne wyszukiwanie odpowiedzi w internecie podczas kolokwium",
                        },
                        results: {
                            title: "Lepsze wyniki",
                            description: "Nie martw się już więcej o zaliczenia przedmiotów!",
                        },
                    },
                    quizionatorInfo: {
                        title: "Czym jest właściwie Quizonator?",
                        description: "Quizonator to rozszerzenie do Chrome, które automatycznie rozwiązuje quizy za Ciebie. <strong>Oparte na najnowszym ChatGPT-4o</strong>, szybko i dokładnie zaznacza poprawne odpowiedzi, oszczędzając Twój czas, marnowany na naukę bezsensownych przedmiotów.",
                        point1: "Stworzone przez studenta dla studentów.",
                        point2: "Przetestowane przez studentów na realnych egzaminach online.",
                        point3: "Jest niewykrywalne i bezpieczne na platformach uczelni.",
                        point4: "Posiada funkcję, która dyskretnie zaznacza poprawne odpowiedzi.",
                    },
                    comparison: {
                        title: "Porównanie: Quizonator vs ChatGpt-4o",
                        automaticQuestionFinding: "Automatyczne znajdowanie pytań:",
                        responseSpeed: "Szybkość odpowiedzi:",
                        bypassingSecurity: "Ominięcie zabezpieczeń uczelni:",
                        undetectability: "Niewykrywalność na egzaminach:",
                        yes: "Tak",
                        no: "Nie",
                        fast: 'około 1 sekundy',
                        slow: 'około 10 sekund',
                    },
                    testimonials: {
                        title: "Jakie są odczucia pierwszych testerów?",
                        bartek: {
                            name: "Bartek",
                            title: "Student, Akademia Humanitas",
                            quote: "Dzięki programowi tak naprawdę zdałem 1 rok studiów, działa giga dobrze, bardzo ciężko go wykryć...",
                        },
                        dominika: {
                            name: "Dominika",
                            title: "Studentka, Uniwersytet WSB Merito",
                            quote: "Genialna wtyczka, która ułatwiła mi jeszcze bardziej rozwiązywanie testów/quizow dając tym samym gwarancje zaliczenia.",
                        },
                        wiktor: {
                            name: "Wiktor",
                            title: "Student, Uniwersytet WSB Merito",
                            quote: "Zajebista opcja safe mod, pomimo że wykładowca stał nade mną dobrze wiedziałem jakie odpowiedzi zaznaczyc polecam",
                        },
                    },
                    about: {
                        intro: "Jeśli jeszcze się nie znamy...",
                        greeting: "Cześć, jestem",
                        name: "Dominik!",
                        description1: "Jestem studentem drugiego roku informatyki, a moja przygoda z programowaniem rozpoczęła się w wieku 13 lat. Programowanie stało się moją pasją i sposobem na rozwiązywanie problemów oraz ułatwianie życia sobie i innym.",
                        description2: "<strong>Jako student wiem, jak cenny jest czas.</strong> Dlatego stworzyłem Quizonatora – aplikację, która <strong>automatycznie rozwiązuje quizy i zaliczenia</strong>, pozwalając skupić się na tym, co naprawdę ważne. Quizonator jest funkcjonalny i przetestowany na jednej z popularnych platform edukacyjnych, a ja ciągle pracuję nad jego udoskonaleniem i rozszerzaniem kompatybilności na inne uczelnie.",
                        description3: "Dołącz do naszej społeczności, <strong>wypróbuj</strong> Quizonatora i pomóż nam stworzyć narzędzie, które naprawdę zmieni sposób studiowania. <strong>Twoje wsparcie i opinie</strong> są dla nas niezwykle cenne w dalszym rozwijaniu tej aplikacji.",
                    },
                    faq: {
                        question1: "Czy rozszerzenie działa na wszystkich stronach z quizami?",
                        answer1: "Obecnie nasze rozszerzenie zostało przetestowane i jest kompatybilne z platformą Moodle, w trybie automatycznego wykrywania pytań. Stale je rozwijam, aby było kompatybilne z każdą stroną z quizami online. Będzie to możliwe dzięki Twojej pomocy!",
                        question2: "Czy rozszerzenie może zostać wykryte przez platformę uczelni?",
                        answer2: "Rozszerzenie zostało zaprojektowane tak, aby działało w tle, nie zakłócając funkcjonowania platformy uczelni. Zostały przeprowadzone również testy na egzaminach z aktywnym zabezpieczeniem przed zmianą karty w przeglądarce i rozszerzenie działało bez żadnych problemów.",
                        question3: "Czy moje dane są bezpieczne?",
                        answer3: "Twoje dane są w pełni bezpieczne. Nie są udostępniane osobom trzecim oraz są stosowane zaawansowane metody szyfrowania. Możesz być pewien, że nikt niepożądany nie dowie się, że korzystasz z naszego rozszerzenia.",
                        question4: "Czy korzystanie z tego narzędzia jest legalne?",
                        answer4: "Korzystanie z Quizonatora jest podobne do korzystania z ChatGPT czy notatek podczas egzaminu online. Jednakże, pamiętaj, że uczelnie mają własne regulaminy dotyczące takich praktyk.",
                    },
                }
            }
        },
        lng: 'pl', // Domyślny język
        fallbackLng: 'en', // Język zapasowy
        interpolation: {
            escapeValue: false
        }
    });

export default i18n;