import React, { useEffect, useState } from 'react';
// import resultImage from '../assets/images/laptop-student.webp'; // Ścieżka do obrazu
 

function CallToAction() {
    const [remainingSlots, setRemainingSlots] = useState(50); // Początkowa liczba miejsc
    const [isPopupVisible, setIsPopupVisible] = useState(false); // Stan popupa
    const [isEmailValid, setIsEmailValid] = useState(false); // Stan walidacji emaila
    const [emailErrorMessage, setEmailErrorMessage] = useState(''); // Komunikat o błędzie emaila

    useEffect(() => {
        async function fetchRemainingSlots() {
            const response = await fetch('https://us-central1-quizonator-a4513.cloudfunctions.net/api/remaining-slots');
            const data = await response.json();
            setRemainingSlots(50 - data.remainingSlots);
        }

        fetchRemainingSlots();
    }, []);

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleEmailChange = (event) => {
        const email = event.target.value;
        const isValid = validateEmail(email);
        setIsEmailValid(isValid);
        setEmailErrorMessage(isValid ? '' : 'Proszę podać prawidłowy adres email.');
    };

    const handleFormSubmit = (event) => {
        event.preventDefault();
        const emailInput = document.getElementById('mce-EMAIL').value;

        if (!validateEmail(emailInput)) {
            setEmailErrorMessage('Proszę podać prawidłowy adres email.');
            return;
        }

        const testGroupValue = Math.random() < 0.5 ? 'A' : 'B';
        const testGroupField = document.getElementById('test-group-field');
        testGroupField.value = testGroupValue;
        setIsPopupVisible(true); // Pokaż popup

        document.getElementById('mc-embedded-subscribe-form').submit();
    };

    return (
        <section id="call-to-action" aria-labelledby="cta-heading" className="bg-purple">
            <div className="flex flex-col md:flex-row xl:h-screen">
                <div className="md:w-1/2 block md:hidden mt-0 md:mt-0">
                    <img src="/laptop-student.webp" alt="Młody student w okularach, uśmiechnięty, pracuje na laptopie." className="w-full h-full object-cover" width="1920" height="1080" />
                </div>
                <div className="md:w-1/2 sm:p-4 pb-6 mt-4 md:mt-12">
                    <div className='max-w-2xl m-auto px-4'>
                        <h1 id="cta-heading" className="md:text-5xl text-4xl font-bold text-white text-start">
                            Też
                            <span className="relative inline-block ml-2 mb-3">
                                <span className="z-10 relative text-light-green ">nie chce Ci się</span>
                            </span>
                            <span> </span>
                            <span className=''>rozwiązywać quizów</span>
                            <span> </span>
                            <span className="relative inline-block mt-3">
                                <span className="z-10 relative">na studiach?</span>
                            </span>
                        </h1>
                        <p className="text-white mt-4 text-start md:text-3xl text-xl">Nasze rozszerzenie rozwiąże je za Ciebie. <strong>Szybko</strong>, <strong>łatwo</strong> i <strong>skutecznie</strong>.</p>
                        <form
                            action="https://app.us17.list-manage.com/subscribe/post?u=96faeecdb580fabfad6cf74b4&amp;id=498535394c&amp;f_id=008fc2e1f0"
                            method="post"
                            id="mc-embedded-subscribe-form"
                            name="mc-embedded-subscribe-form"
                            className="validate mt-8 md:mt-20 border-2 border-white rounded-3xl p-6 shadow-lg"
                            target="hidden_iframe"
                            noValidate
                            aria-label="Formularz zapisu na darmowe testy"
                            onSubmit={handleFormSubmit}
                        >
                            <div className="flex items-center my-4">
                                <div className="flex-grow border-t border-white"></div>
                                <span className="mx-4 text-white font-semibold">Dołącz do naszej społeczności</span>
                                <div className="flex-grow border-t border-white"></div>
                            </div>
                            <div className='flex gap-4'>
                                <input
                                    type="email"
                                    id="mce-EMAIL"
                                    name="EMAIL"
                                    placeholder="Adres email..."
                                    required
                                    className="mt-1 block w-full px-4 py-3 border-0 rounded-md shadow-sm focus:outline-none focus:border focus:ring-light-green focus:border-light-green transition duration-200 ease-in-out transform focus:scale-105"
                                    onChange={handleEmailChange}
                                />
                                <input
                                    type="hidden"
                                    id="test-group-field"
                                    name="MMERGE2"
                                />
                            </div>
                            {emailErrorMessage && (
                                <small className="block  mt-1 text-start text-red-500">{emailErrorMessage}</small>
                            )}
                            <button
                                id="mc-embedded-subscribe"
                                type="submit"
                                className={`mt-6 w-full py-3 md:px-6 font-bold sm:text-xl rounded-md shadow-sm transition duration-200 ease-in-out ${isEmailValid ? 'bg-light-green hover:bg-light-green/90 transform hover:scale-105 cursor-pointer' : 'bg-light-green hover:bg-light-green/90 transform hover:scale-105 cursor-pointer'}`}
                            >
                                Zapisz mnie na darmowe testy
                            </button>

                            <small className="block mt-4 text-white text-xs font-semibold">Nie udostępniamy Twoich danych nikomu</small>
                            <small className="block mt-4 text-white font-semibold">Pozostało tylko <span className="text-light-green">{remainingSlots} miejsc</span>! Zapisz się teraz!</small>
                            <div className="w-full bg-gray-200 rounded-full h-2.5 mt-4">
                                <div className="bg-light-green h-2.5 rounded-full" style={{ width: `${(remainingSlots / 50) * 100}%` }}></div>
                            </div>
                        </form>
                        {isPopupVisible && (
                            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 animate-fade-in">
                                <div className="bg-white shadow-custom-green p-8 rounded-md text-center animate-pop-up sm:mr-0 mr-2">
                                    <h2 className="text-3xl font-bold mb-4">Dziękuję Ci za zapisanie się!</h2>
                                    <p className="mb-4 text-lg">Wkrótce otrzymasz email z dalszymi krokami.</p>
                                    <button
                                        onClick={() => setIsPopupVisible(false)}
                                        className="mt-4 py-2 px-6 bg-light-green font-bold rounded-md shadow-sm hover:bg-light-green/90 focus:outline-none focus:ring-2 focus:ring-light-green transition duration-200 ease-in-out"
                                    >
                                        Zamknij
                                    </button>
                                </div>
                            </div>
                        )}
                        <iframe name="hidden_iframe" id="hidden_iframe" style={{ display: 'none' }}></iframe>
                    </div>
                </div>
                <div className="md:w-1/2 hidden md:block mt-0 md:mt-0">
                    <img src="/laptop-student.webp" alt="Młody student w okularach, uśmiechnięty, pracuje na laptopie." className="w-full h-full object-cover"  width="1920" height="1080" />
                </div>
            </div>
        </section>
    );
}

export default CallToAction;
