import React from 'react';
import quizonatorLogo from '../assets/images/quizonator-logo.png'; // Ścieżka do obrazu Quizonator
import chatgptLogo from '../assets/images/chatgpt-logo.png'; // Ścieżka do obrazu ChatGPT

function Comparison() {
  return (
    <section className='bg-dark-gray'>
      <div className="text-white md:p-8 pb-6 pt-12 p-2 rounded-lg max-w-5xl mx-auto">
        <h1 className="text-center text-4xl font-bold mb-16">Porównanie: Quizonator vs ChatGpt-4o</h1>
        <div className="grid grid-cols-3 md:grid-cols-4 gap-4 text-center">
          <div className='md:col-span-2'></div>
          <div className="flex items-center justify-center">
            <img src={quizonatorLogo} alt="Quizonator" className="mx-auto md:col-span-1 w-16 h-16 md:w-24 md:h-24 mb-4" />
          </div>
          <div className="flex items-center justify-center">
            <img src={chatgptLogo} alt="ChatGpt-4o" className="mx-auto md:col-span-1 w-16 h-16 md:w-24 md:h-24 mb-4" />
          </div>
        </div>
        <div className="grid grid-cols-3 md:grid-cols-4 gap-4">
          <div className="md:col-span-2 py-2 flex items-center justify-center text-sm md:text-xl">Automatyczne znajdowanie pytań:</div>
          <div className="md:col-span-1 py-2 flex items-center justify-center text-lg md:text-xl text-neon-green font-bold">TAK</div>
          <div className="md:col-span-1 py-2 flex items-center justify-center text-lg md:text-xl text-white">Nie</div>
        </div>
        <div className="grid grid-cols-3 md:grid-cols-4 gap-4  md:text-xl mt-6">
          <div className="md:col-span-2 py-2 flex items-center justify-center text-sm md:text-xl">Szybkość odpowiedzi:</div>
          <div className="md:col-span-1 py-2 flex items-center justify-center text-lg md:text-xl text-neon-green font-bold">Około 1 sekunda</div>
          <div className="md:col-span-1 py-2 flex items-center justify-center text-lg md:text-xl text-white">Co najmniej  7 sekund</div>
        </div>
        <div className="grid grid-cols-3 md:grid-cols-4 gap-4  md:text-xl mt-6">
          <div className="md:col-span-2 py-2 flex items-center justify-center text-sm md:text-xl">Ominięcie zabezpieczeń uczelni:</div>
          <div className="md:col-span-1 py-2 flex items-center justify-center text-lg md:text-xl text-neon-green font-bold">TAK</div>
          <div className="md:col-span-1 py-2 flex items-center justify-center text-lg md:text-xl text-white">NIE</div>
        </div>
        <div className="grid grid-cols-3 md:grid-cols-4 gap-4 text-sm md:text-xl mt-6">
          <div className="md:col-span-2 py-2 flex items-center justify-center text-sm md:text-xl">Niewykrywalność na egzaminach:</div>
          <div className="md:col-span-1 py-2 flex items-center justify-center text-lg md:text-xl text-neon-green font-bold">TAK</div>
          <div className="md:col-span-1 py-2 flex items-center justify-center text-lg md:text-xl text-white">NIE</div>
        </div>
        {/* <div className="grid grid-cols-3 md:grid-cols-4 gap-4 text-sm md:text-xl mt-6">
          <div className="md:col-span-2 py-2 flex items-center justify-center">Koszt subskrypcji:</div>
          <div className="md:col-span-1 py-2 flex items-center justify-center text-neon-green font-bold">24,99zł</div>
          <div className="md:col-span-1 py-2 flex items-center justify-center text-red-500">99,99zł</div>
        </div> */}
        {/* <div className="grid grid-cols-3 md:grid-cols-4 gap-4 text-sm md:text-xl mt-6">
          <div className="md:col-span-2 py-2 flex items-center justify-center">Korzystanie z najnowszego modelu AI:</div>
          <div className="md:col-span-1 py-2 flex items-center justify-center text-neon-green font-bold">Tak (ChatGPT-4o)</div>
          <div className="md:col-span-1 py-2 flex items-center justify-center text-red-500">Tak (ChatGPT-4o)</div>
        </div> */}
      </div>
    </section>
  );
}

export default Comparison;
