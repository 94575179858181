import React from 'react';
import myPhoto from '../assets/images/my-photo.png'; // Ścieżka do twojego zdjęcia

function About() {
    return (
        <section className="md:pb-12 pt-12 bg-purple-700 text-white">
            <div className="max-w-7xl mx-auto px-8 flex flex-col md:flex-row items-center text-start">
                <div className="md:w-1/2">
                    <p className="text-lg">Jeśli jeszcze się nie znamy...</p>
                    <h2 className="text-4xl font-bold mb-4 relative">
                        Cześć, jestem <span className="relative inline-block">
                            <span className="text-yellow-500 z-10 relative text-light-green">Dominik!</span>
                            <span className="absolute bottom-0 left-0 w-full h-2/3  z-0"></span>
                        </span>
                    </h2>
                    <div className="flex md:hidden md:w-1/2 my-8  justify-center">
                        <div className="bg-gray-300 rounded-lg w-full md:h-full  flex items-center justify-center overflow-hidden shadow-custom-white">
                            <img src={myPhoto} alt="Dominik" loading='lazy' className="object-cover w-full h-full" />
                        </div>
                    </div>
                    <p className="mb-6 text-lg">Jestem studentem drugiego roku informatyki, a moja przygoda z programowaniem rozpoczęła się w wieku 13 lat. Programowanie stało się moją pasją i sposobem na rozwiązywanie problemów oraz ułatwianie życia sobie i innym.</p>
                    <p className="mb-6 text-lg"><strong>Jako student wiem, jak cenny jest czas.</strong> Dlatego stworzyłem Quizonatora – aplikację, która <strong>automatycznie rozwiązuje quizy i zaliczenia</strong>, pozwalając skupić się na tym, co naprawdę ważne. Quizonator jest funkcjonalny i przetestowany na jednej z popularnych platform edukacyjnych, a ja ciągle pracuję nad jego udoskonaleniem i rozszerzaniem kompatybilności na inne uczelnie.</p>
                    <p className="mb-6 text-lg">Dołącz do naszej społeczności, <strong>wypróbuj</strong> Quizonatora i pomóż nam stworzyć narzędzie, które naprawdę zmieni sposób studiowania. <strong>Twoje wsparcie i opinie</strong> są dla nas niezwykle cenne w dalszym rozwijaniu tej aplikacji.</p>
                </div>
                <div className="hidden md:flex md:w-1/2 mt-8 md:mt-0  justify-center">
                    <div className="bg-gray-300 rounded-lg w-full md:max-w-lg h-96 md:h-full  flex items-center justify-center overflow-hidden shadow-custom-white">
                        <img src={myPhoto} alt="Dominik" className="object-cover w-full h-full" />
                    </div>
                </div>
            </div>
        </section>
    );
}

export default About;
