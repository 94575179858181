import React, { useState } from 'react';

const faqs = [
    {
        question: 'Czy rozszerzenie działa na wszystkich stronach z quizami?',
        answer: 'Obecnie nasze rozszerzenie zostało przetestowane i jest kompatybilne z platformą Moodle, w trybie automatycznego wykrywania pytań. Stale je rozwijam, aby było kompatybilne z każdą stroną z quizami online. Będzie to możliwe dzięki Twojej pomocy!',
    },
    {
        question: 'Czy rozszerzenie może zostać wykryte przez platformę uczelni?',
        answer: 'Rozszerzenie zostało zaprojektowane tak, aby działało w tle, nie zakłócając funkcjonowania platformy uczelni. Zostały przeprowadzone również testy na egzaminach z aktywnym zabezpieczeniem przed zmianą karty w przeglądarce i rozszerzenie działało bez żadnych problemów.',
    },
    {
        question: 'Czy moje dane są bezpieczne?',
        answer: 'Twoje dane są w pełni bezpieczne. Nie są udostępniane osobom trzecim oraz są stosowane zaawansowane metody szyfrowania. Możesz być pewien, że nikt niepożądany nie dowie się, że korzystasz z naszego rozszerzenia.',
    },
    {
        question: 'Czy korzystanie z tego narzędzia jest legalne?',
        answer: 'Korzystanie z Quizonatora jest podobne do korzystania z ChatGPT czy notatek podczas egzaminu online. Jednakże, pamiętaj, że uczelnie mają własne regulaminy dotyczące takich praktyk.',
    },
];

function Faq() {
    const [activeIndices, setActiveIndices] = useState([]);

    const toggleFAQ = (index) => {
        if (activeIndices.includes(index)) {
            setActiveIndices(activeIndices.filter((i) => i !== index));
        } else {
            setActiveIndices([...activeIndices, index]);
        }
    };

    return (
        <section className="py-12 bg-white">
            <div className="max-w-4xl mx-auto px-4">
                <h2 className="text-4xl font-semibold text-center text-black mb-8">Często Zadawane Pytania</h2>
                <div className="space-y-4">
                    {faqs.map((faq, index) => (
                        <div key={index} className=" rounded-lg  p-4 text-dark-gray">
                            <button
                                className="w-full text-left flex justify-between items-center text-lg font-semibold border-b pb-2"
                                onClick={() => toggleFAQ(index)}
                            >
                                {faq.question}
                                <span className={`transform transition-transform ${activeIndices.includes(index) ? 'rotate-180' : 'rotate-0'}`}>
                                    &#9662;
                                </span>
                            </button>
                            {activeIndices.includes(index) && (
                                <div className="mt-4 text-gray-700 text-justify">
                                    <p>{faq.answer}</p>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}

export default Faq;
