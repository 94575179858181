import React from 'react';
import { ReactComponent as StarSvg } from '../assets/svg/medical-star.svg';

function QuzionatorInfo() {
    return (
        <section className="py-12 bg-white text-dark-gray">
            <div className="max-w-7xl mx-auto px-8 text-center md:p-0">
                <h2 className="text-4xl font-bold mb-8">Czym jest właściwie Quizonator?</h2>
                <div className="text-left space-y-8 sm:p-8">
                    <p className="text-lg text-justify">
                        Quizonator to rozszerzenie do Chrome, które automatycznie rozwiązuje quizy za Ciebie. <strong>Oparte na najnowszym ChatGPT-4o</strong>,
                        szybko i dokładnie zaznacza poprawne odpowiedzi, oszczędzając Twój czas, marnowany na naukę bezsensownych przedmiotów.
                    </p>
                    <div className="flex items-start p-2">
                        <div className="w-6 h-6 mr-4 flex-shrink-0 flex items-center justify-center mt-2">
                            <StarSvg className='w-full h-full' fill="#6b21a8" />
                        </div>
                        <p className="text-lg flex-1">
                        Quizonator został stworzony przez studenta dla studentów.
                        </p>
                    </div>
                    <div className="flex items-start p-2">
                        <div className="w-6 h-6  mr-4 flex-shrink-0 flex items-center justify-center mt-2">
                            <StarSvg className='w-full h-full' fill="#6b21a8" />
                        </div>
                        <p className="text-lg flex-1">
                            Przetestowany przez studentów na realnych egzaminach online.
                        </p>
                    </div>
                    <div className="flex items-start  p-2">
                        <div className="w-6 h-6 mr-4 flex-shrink-0 flex items-center justify-center mt-2">
                            <StarSvg className='w-full h-full' fill="#6b21a8" />
                        </div>
                        <p className="text-lg flex-1">
                            Jest niewykrywalny i bezpieczny na platformach uczelni. 
                        </p>
                    </div>
                    <div className="flex items-start  p-2">
                        <div className="w-6 h-6 mr-4 flex-shrink-0 flex items-center justify-center mt-2">
                            <StarSvg className='w-full h-full' fill="#6b21a8" />
                        </div>
                        <p className="text-lg flex-1">
                            Posiada funkcję, która dyskretnie zaznacza poprawne odpowiedzi.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default QuzionatorInfo;
