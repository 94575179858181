import React from 'react';

function Footer() {
    const scrollToCallToAction = () => {
        const callToActionElement = document.getElementById('cta-heading');
        const submitButton = document.getElementById('mc-embedded-subscribe');
        
        // Oblicz pozycję elementu
        const elementPosition = callToActionElement.getBoundingClientRect().top + window.pageYOffset;
    
        // Przesuń stronę do pozycji elementu minus 10 px
        window.scrollTo({
            top: elementPosition - 15,
            behavior: 'smooth'
        });
    
        // Dodaj klasę animacji do przycisku
        submitButton.classList.add('animate-rainbow');
    
        // Usuń klasę animacji po 2 sekundach
        setTimeout(() => {
            submitButton.classList.remove('animate-rainbow');
        }, 2000);
    };
    

    return (
        <footer className="bg-dark-gray py-6">
            <div className="text-center">
                <button
                    className="bg-light-green border-none text-black font-bold py-2 px-4 rounded shadow hover:bg-light-green/90"
                    onClick={scrollToCallToAction}
                >
                    Chcę zostać testerem
                </button>
            </div>
        </footer>
    );
}

export default Footer;
