import React from 'react';
// import dominikImage from '../assets/images/my-photo.png'; // Przykładowe zdjęcie

function Testimonials() {
    return (
        <section className="py-12">
            <div className="max-w-7xl mx-auto px-8 text-center">
                <h2 className="text-4xl font-bold text-dark-gray mb-12">Jakie są odczucia pierwszych testerów?</h2>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                    <div className="relative bg-white p-6 rounded-lg border-2 border-black shadow-custom-green"  >
                        <div className="items-center mb-4 text-start">
                            {/* <div className="w-10 h-10 bg-gray-300 rounded-full flex-shrink-0 mr-4">
                                <img src={dominikImage} alt="Dominik's profile" className="w-full h-full rounded-full object-cover" />
                            </div> */}
                            <h3 className="text-lg font-bold text-dark-gray">Bartek</h3>
                            <h2 className="text-sm   text-light-gray">Student, Akademia Humanitas</h2>
                        </div>
                        <p className="text-gray-600 mb-4 text-justify">"Dzięki programowi tak naprawdę zdałem 1 rok studiów, działa giga dobrze, bardzo ciężko go wykryć.
                        Jedyny mankament jaki posiada to w pytaniach z kilkoma prawidłowymi odpowiedziami czasem świruje ale w większości przypadków wystarczy tylko przeklikać na inne pytanie i wrócić i wszystko działa perfekcyjnie."</p>
                        <div className="flex justify-start ">
                            <span className="text-yellow text-3xl tracking-widest">★★★★★</span>
                        </div>
                    </div>
                    <div className="relative bg-white p-6 rounded-lg border-2 border-black shadow-custom-green"  >
                        <div className="items-center mb-4 text-start">
                            {/* <div className="w-10 h-10 bg-gray-300 rounded-full flex-shrink-0 mr-4"></div> */}
                            <h3 className="text-lg font-bold text-gray-800">Dominika</h3>
                            <h2 className="text-sm   text-light-gray">Studentka, Uniwersytet WSB Merito</h2>
                        </div>
                        <p className="text-gray-600 mb-4 text-justify">"Genialna wtyczka, która ułatwiła mi jeszcze bardziej rozwiązywanie testów/quizow dając tym samym gwarancje zaliczenia."</p>
                        <div className="flex justify-start">
                            <span className="text-yellow text-3xl tracking-widest">★★★★★</span>
                        </div>
                    </div>
                    <div className="relative bg-white p-6 rounded-lg  border-2 border-black shadow-custom-green"  >
                        <div className="items-center mb-4 text-start">
                            {/* <div className="w-10 h-10 bg-gray-300 rounded-full flex-shrink-0 mr-4"></div> */}
                            <h3 className="text-lg font-bold text-dark-gray">Wiktor</h3>
                            <h2 className="text-sm  text-light-gray">Student, Uniwersytet WSB Merito</h2>

                        </div>
                        <p className="text-gray-600 mb-4 text-justify">"Zajebista opcja safe mod, pomimo że wykładowca stał nade mną dobrze wiedziałem jakie odpowiedzi zaznaczyc polecam"</p>
                        <div className="flex justify-start">
                        <span className="text-yellow text-3xl tracking-widest">★★★★★</span>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Testimonials;
