import React from 'react';

import { ReactComponent as TimeSvg } from '../assets/svg/time.svg';
import { ReactComponent as StressSvg } from '../assets/svg/meditation.svg';
import { ReactComponent as GradeSvg } from '../assets/svg/career-growth.svg';

function Benefits() {
    return (
        <section className="bg-dark-gray py-8">
            <div className="max-w-5xl mx-auto px-8 grid grid-cols-1 md:grid-cols-3 gap-12 text-white text-center">
                <div className="flex flex-col items-center">
                    <div className="mb-4">
                        <TimeSvg fill="#fff" className="w-12 h-12" />
                    </div>
                    <h1 className="text-xl font-bold">Więcej czasu</h1>
                    <p className="mt-2">Zaoszczędź godziny nauki przedmiotów, które musisz zdać, ale nigdy nie będą ci potrzebne.</p>
                </div>
                <div className="flex flex-col items-center">
                    <div className="mb-4">
                        <StressSvg fill="#fff" className="w-12 h-12" />

                    </div>
                    <h1 className="text-xl font-bold">Brak stresu</h1>
                    <p className="mt-2">Pożegnaj paniczne wyszukiwanie odpowiedzi w internecie podczas kolokwium</p>
                </div>
                <div className="flex flex-col items-center">
                    <div className="mb-4">
                        <GradeSvg fill="#fff" className="w-12 h-12" />

                    </div>
                    <h1 className="text-xl font-bold">Lepsze wyniki</h1>
                    <p className="mt-2">Nie martw się już więcej o zaliczenia przedmiotów!</p>
                </div>
            </div>
        </section>
    );
}

export default Benefits;
